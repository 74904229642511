import { Handle, HandleProps, useHandleConnections } from '@xyflow/react'

const NodeHandle = (props: HandleProps) => {
  /**
   * ----- Hook Initialization -----
   */

  const connections = useHandleConnections({
    type: props.type,
    id: props.id
  })

  /**
   * ----- Variables -----
   */

  const isConnectable =
    props.type === 'source' ? connections.length === 0 : true

  /**
   * ----- Render -----
   */

  return <Handle {...props} isConnectable={isConnectable} />
}

export default NodeHandle
