import { Position } from '@xyflow/react'
import React from 'react'
import { useModuleFormContext } from 'src/contexts/ModuleForm'
import { IFreeFormResponse } from 'src/models/module'
import {
  ModuleContainer,
  ModuleHeaderContainer
} from 'src/shared/styled/Module'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import FirstTaskButton from './FirstTaskButton'
import NodeHandle from './NodeHandle'

interface IFreeFormResponseNode {
  data: IFreeFormResponse
}

const FreeFormResponseNode = React.memo(({ data }: IFreeFormResponseNode) => {
  /**
   * ----- Hook Initialization -----
   */

  const { moduleFormData, updateTask } = useModuleFormContext()

  /**
   * ----- Render -----
   */

  return (
    <>
      <NodeHandle type="target" position={Position.Top} />
      <ModuleContainer>
        <ModuleHeaderContainer>
          <h3>Free Form Response</h3>
          <FirstTaskButton taskId={data.taskId} />
        </ModuleHeaderContainer>
        <LanguageMapForm
          value={data.text}
          onChange={(value) => {
            updateTask({ ...data, text: value })
          }}
          supportedLanguages={moduleFormData.supportedLanguages}
          textFieldProps={{
            multiline: true,
            minRows: 2
          }}
        />
      </ModuleContainer>
      <NodeHandle type="source" position={Position.Bottom} />
    </>
  )
})

export const FreeFormResponseNodeForm = ({ data }: IFreeFormResponseNode) => {
  /**
   * ----- Hook Initialization -----
   */

  const { moduleFormData, updateTask } = useModuleFormContext()

  /**
   * ----- Render -----
   */

  return (
    <div>
      <h3>Free Form Response</h3>
      <LanguageMapForm
        value={data.text}
        onChange={(value) => {
          updateTask({ ...data, text: value })
        }}
        supportedLanguages={moduleFormData.supportedLanguages}
        textFieldProps={{
          multiline: true
        }}
      />
    </div>
  )
}

export default FreeFormResponseNode
