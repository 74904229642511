import { Add, DragIndicator } from '@material-ui/icons'
import React from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { useModuleFormContext } from 'src/contexts/ModuleForm'
import { IAnswerOption } from 'src/models/module'
import { SelectableOptionContainer } from 'src/shared/styled/Module'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

const AddOptionText = styled.span`
  font-size: 1.25em;
`

interface ISelectableOptionList {
  taskId: string
  selectableOptions: IAnswerOption[]
  singleSelect?: boolean
  onOptionsChange: (options: IAnswerOption[]) => void
}

const SelectableOptionList = ({
  taskId,
  selectableOptions,
  onOptionsChange,
  singleSelect
}: ISelectableOptionList) => {
  /**
   * ----- Hook Initialization -----
   */

  const { moduleFormData, refresh } = useModuleFormContext()

  /**
   * ----- Functions -----
   */

  const onDragEnd = React.useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) return

      const newOptions = [...selectableOptions]
      const [removed] = newOptions.splice(source.index, 1)
      newOptions.splice(destination.index, 0, removed)

      onOptionsChange(newOptions)
    },
    [onOptionsChange, selectableOptions]
  )

  /**
   * ----- Render -----
   */

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={taskId}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {selectableOptions.map((option, index) => (
              <Draggable
                key={option.selectionId}
                draggableId={option.selectionId}
                index={index}
              >
                {(provided) => (
                  <SelectableOptionContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicator />
                    <LanguageMapForm
                      value={option.text}
                      onChange={(value) => {
                        const newOptions = [...selectableOptions]
                        newOptions[index] = { ...option, text: value }
                        onOptionsChange(newOptions)
                      }}
                      supportedLanguages={moduleFormData.supportedLanguages}
                      textFieldProps={{
                        multiline: true
                      }}
                    />
                  </SelectableOptionContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <SelectableOptionContainer
        style={{ justifyContent: 'center', padding: '10px', cursor: 'pointer' }}
        onClick={() => {
          onOptionsChange([
            ...selectableOptions,
            {
              selectionId: uuidv4(),
              text: {}
            }
          ])
          if (singleSelect) refresh()
        }}
      >
        <Add />
        <AddOptionText>New Option</AddOptionText>
      </SelectableOptionContainer>
    </DragDropContext>
  )
}

export default SelectableOptionList
