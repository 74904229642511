import React from 'react'
import { IModule, IModuleFormData } from 'src/models/module'
import { useUpdateModule } from 'src/shared/hooks/modules'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Container, HeaderContainer } from 'src/shared/styled/Module'
import { PageLevelHeading } from 'src/shared/styled/Text'
import ModuleFormContainer from '../containers/ModuleForm'

interface IModuleProps {
  module: IModule
}

const Module = ({ module }: IModuleProps) => {
  /**
   * ----- Hook Initialization -----
   */

  const [data, setData] = React.useState<IModuleFormData>(module)

  const { isLoading, updateModule } = useUpdateModule(module.moduleId)

  /**
   * ----- Functions -----
   */

  const onChange = React.useCallback((formData: IModuleFormData) => {
    setData(formData)
  }, [])

  const onSave = React.useCallback(() => {
    updateModule(data)
  }, [data, updateModule])

  /**
   * ----- Render -----
   */

  return (
    <Container>
      <HeaderContainer>
        <PageLevelHeading>{module.title.en}</PageLevelHeading>
        <ButtonWithWidth disabled={isLoading} onClick={onSave}>
          Save
        </ButtonWithWidth>
      </HeaderContainer>
      <ModuleFormContainer module={module} onChange={onChange} />
    </Container>
  )
}

export default Module
