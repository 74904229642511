import styled from 'styled-components'
import { COLORS } from '../theme'

export const Container = styled.div`
  padding: 50px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export const SelectableOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 5px;
  padding: 5px;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  :hover {
    background-color: ${COLORS.slate[100]};
  }
`

export const ModuleContainer = styled.div`
  padding: 5px 5px 0 5px;
`

export const ModuleHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
`
