import { Node } from '@xyflow/react'
import { LanguageMap, UUID } from './misc'

export interface IModule {
  moduleId: UUID
  firstTask: UUID
  title: LanguageMap
  description?: LanguageMap
  version: number
  coverImageUrl?: string
  tasks: Record<UUID, ModuleTask>
  supportedLanguages: ('en' | 'fr')[]
}

export enum ModuleTaskType {
  SingleSelectQuestion = 'SingleSelectQuestion',
  MultiSelectQuestion = 'MultiSelectQuestion',
  Message = 'Message',
  FreeFormResponse = 'FreeFormResponse'
}

export interface ISingleSelectQuestion {
  taskId: UUID
  text: LanguageMap
  type: ModuleTaskType.SingleSelectQuestion
  selectableOptions: IAnswerOption[]
  // Record<answerOptionId, taskId>
  navigationMap: Record<UUID, UUID>
  correctAnswer?: UUID
}

export interface IMultiSelectQuestion {
  taskId: UUID
  text: LanguageMap
  type: ModuleTaskType.MultiSelectQuestion
  followUpTask?: UUID
  selectableOptions: IAnswerOption[]
}

export interface IAnswerOption {
  selectionId: UUID
  text: LanguageMap
}

export interface IMessage {
  taskId: UUID
  text: LanguageMap
  type: ModuleTaskType.Message
  followUpTask?: UUID
}

export interface IFreeFormResponse {
  taskId: UUID
  text: LanguageMap
  type: ModuleTaskType.FreeFormResponse
  followUpTask?: UUID
}

export type ModuleTask =
  | ISingleSelectQuestion
  | IMultiSelectQuestion
  | IMessage
  | IFreeFormResponse

export type ModuleTaskNode = Node<ModuleTask & Record<string, unknown>>

export interface IModuleFormData {
  title: LanguageMap
  description?: LanguageMap
  coverImageUrl?: string
  tasks: Record<UUID, ModuleTask>
  supportedLanguages: ('en' | 'fr')[]
  firstTask?: UUID
}
