import { AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ProgramsApi, UsersApi } from 'src/api/client'
import {
  FETCH_ALL_PROGRAMS,
  FETCH_PROGRAM,
  FETCH_PROGRAM_WAIT_LIST,
  FETCH_SEARCHED_PROGRAM
} from 'src/constants/queries'
import {
  IDin,
  IEntryPointData,
  IProgram,
  IProgramCreateData,
  IProgramSearch,
  IWaitList
} from 'src/models/program'
import { logger } from 'src/utils/logger'

const defaultQueryConfig = {
  refetchOnWindowFocus: false
}

/**
 * ----- Queries -----
 */

export const usePrograms = () => {
  const query = useQuery<IProgram[]>(FETCH_ALL_PROGRAMS, () =>
    ProgramsApi.listPrograms()
  )

  return { ...query, programs: query.data }
}

export const useProgramSearch = (value: string, enabled?: boolean) => {
  const queryClient = useQueryClient()

  const query = useQuery<IProgramSearch[]>({
    queryKey: FETCH_SEARCHED_PROGRAM(value),
    queryFn: () => ProgramsApi.searchPrograms(`programNameFragment=${value}`),
    onSuccess: (matchingPrograms: IProgramSearch[]) => {
      if (!value && value !== '') {
        matchingPrograms.map((matchingProgram) =>
          queryClient.setQueryData(
            FETCH_PROGRAM(matchingProgram.programId),
            matchingProgram
          )
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(FETCH_SEARCHED_PROGRAM(value))
    },
    enabled: enabled || false,
    ...defaultQueryConfig
  })

  return { ...query, search: query.refetch, programs: query.data }
}

export const useProgram = (programId: string) => {
  const query = useQuery<IProgram | undefined>({
    queryKey: FETCH_PROGRAM(programId),
    queryFn: () => ProgramsApi.listProgram(programId)
  })

  return { ...query, program: query.data }
}

export const useProgramWaitList = (programId: string) => {
  const query = useQuery<IWaitList[] | undefined>({
    queryKey: FETCH_PROGRAM_WAIT_LIST(programId),
    queryFn: () => ProgramsApi.getWaitList(programId)
  })

  return { ...query, waitList: query.data }
}

/**
 * ----- Mutations -----
 */

export const useCreateProgram = ({
  onSuccess,
  onError
}: {
  onSuccess?: (data: AxiosResponse<string>) => void
  onError?: (err: Error) => void
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IProgramCreateData) => ProgramsApi.createProgram(data),
    {
      onSuccess: (data) => {
        onSuccess?.(data)
      },
      onSettled: () => {
        queryClient.invalidateQueries(FETCH_ALL_PROGRAMS)
      },
      onError: (err: Error) => {
        logger.error('Error creating program', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, create: mutation.mutate }
}

export const useUpdateProgram = (
  id: string,
  {
    onSuccess,
    onError
  }: { onSuccess?: () => void; onError?: (err: unknown) => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: Partial<Omit<IProgram, 'accessCodes' | 'endDate'>>) =>
      ProgramsApi.updateProgram(id, data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(id))
        queryClient.invalidateQueries(FETCH_ALL_PROGRAMS)
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('There was an error updating this program', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, update: mutation.mutate }
}

export const useDeleteProgram = (
  id: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(() => ProgramsApi.deleteProgram(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_ALL_PROGRAMS)
      queryClient.invalidateQueries(FETCH_PROGRAM(id))
      if (onSuccess) onSuccess()
    },
    onError: (err) => {
      logger.error('There was an error deleting this program', err)
    }
  })

  return { ...mutation, delete: mutation.mutate }
}

export const useUpdateProgramEntryPoint = (
  programId: string,
  {
    onSuccess,
    onError
  }: {
    onSuccess?: () => void
    onError?: (err: unknown) => void
  }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IEntryPointData) =>
      ProgramsApi.updateProgramEntryPoint(programId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured updating this entry point', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, updateEntryPoint: mutation.mutate }
}

export const useProgramAddDins = (
  programId: string,
  {
    onSuccess,
    onError
  }: { onSuccess?: () => void; onError?: (err: unknown) => void } = {}
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IDin[]) => ProgramsApi.addDins(programId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured when adding DINs', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, addDins: mutation.mutate }
}

export const useProgramRemoveDins = (
  programId: string,
  {
    onSuccess,
    onError
  }: { onSuccess?: () => void; onError?: (err: unknown) => void } = {}
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IDin[]) => ProgramsApi.removeDins(programId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured when removing DINs', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, removeDins: mutation.mutate }
}

export const useDeleteProgramEntryPoint = (
  programId: string,
  {
    onSuccess,
    onError
  }: {
    onSuccess?: () => void
    onError?: (err: unknown) => void
  }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    () => ProgramsApi.deleteProgramEntryPoint(programId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured deleting this entry point', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, deleteEntryPoint: mutation.mutate }
}

export const useCreateProgramEntryPoint = (
  programId: string,
  {
    onSuccess,
    onError
  }: {
    onSuccess?: () => void
    onError?: (err: unknown) => void
  }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IEntryPointData) =>
      ProgramsApi.createProgramEntryPoint(programId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured creating this entry point', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, createEntryPoint: mutation.mutate }
}

export const useUpdateProgramEndDate = (
  programId: string,
  {
    onSuccess,
    onError
  }: {
    onSuccess?: () => void
    onError?: (err: unknown) => void
  }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (graduationDate: Date) =>
      ProgramsApi.updateEndDate(programId, graduationDate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured when updating program end date', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, updateEndDate: mutation.mutate }
}

export const useGenerateEntryCode = (
  programId: string,
  { onSuccess }: { onSuccess?: (data: any) => void }
) => {
  const mutation = useMutation(() => ProgramsApi.generateEntryCode(programId), {
    onError: (err) => {
      logger.error('An error occured generating entry code', err)
    },
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data)
    }
  })

  return { ...mutation, generateEntryCode: mutation.mutate }
}

export const useAdmitUserToProgram = (
  programId: string,
  { onSuccess }: { onSuccess?: () => void } = {}
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (userId: string) => ProgramsApi.admitUserToProgram(programId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM_WAIT_LIST(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured admitting user to program', err)
      }
    }
  )

  return { ...mutation, admitUser: mutation.mutate }
}

export const useDenyUserFromProgram = (
  programId: string,
  { onSuccess }: { onSuccess?: () => void } = {}
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (userId: string) =>
      UsersApi.deleteProgramMembership(
        programId,
        userId,
        'Denying program enrolment from waitlist'
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM_WAIT_LIST(programId))
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured denying user from program', err)
      }
    }
  )

  return { ...mutation, denyUser: mutation.mutate }
}
