import {
  Background,
  ReactFlow,
  Controls,
  useOnSelectionChange,
  Edge,
  Panel,
  getOutgoers,
  IsValidConnection
} from '@xyflow/react'
import { useModuleFormContext } from 'src/contexts/ModuleForm'

import '@xyflow/react/dist/style.css'
import MessageNode from './Message'
import React from 'react'
import SingleSelectQuestionNode from './SingleSelectQuestion'
import MultiSelectQuestionNode from './MultiSelectQuestion'
import FreeFormResponseNode from './FreeFormResponse'
import { FormControl, Menu, MenuItem } from '@material-ui/core'
import { Add, Refresh } from '@material-ui/icons'
import styled from 'styled-components'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import NodePanel from './NodePanel'
import { ModuleTaskNode, ModuleTaskType } from 'src/models/module'
import { PanelContainer } from 'src/shared/styled/Module'
import SupportedLanguageForm from 'src/shared/views/SupporedLanguageForm'

const FormHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const FormControlContainer = styled(FormControl)`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 15px;
`

const ModuleForm = () => {
  /**
   * ----- Hook Initialization -----
   */

  const [selectedNodes, setSelectedNodes] = React.useState<string[]>([])

  const {
    moduleFormData,
    setModuleFormData,
    nodes,
    edges,
    refresh,
    updateNodes,
    updateEdges,
    addConnection,
    addTask
  } = useModuleFormContext()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  /**
   * ----- Functions -----
   */

  const handleAddMenuOpen = React.useCallback(
    (event: React.MouseEvent<any>) => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleAddMenuClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAddNode = React.useCallback(
    (type: ModuleTaskType) => {
      addTask(type)
      handleAddMenuClose()
    },
    [addTask, handleAddMenuClose]
  )

  const onSelectionChange = React.useCallback(
    ({ nodes, edges: _ }: { nodes: ModuleTaskNode[]; edges: Edge[] }) => {
      setSelectedNodes(nodes.map((node) => node.id))
    },
    []
  )

  const isValidConnection: IsValidConnection = React.useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const target = nodes.find((node) => node.id === connection.target)
      const hasCycle = (node: ModuleTaskNode, visited = new Set()) => {
        if (visited.has(node.id)) return false

        visited.add(node.id)

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true
          if (hasCycle(outgoer, visited)) return true
        }
      }

      if (!target) return false
      if (target.id === connection.source) return false
      return !hasCycle(target)
    },
    [edges, nodes]
  )

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useOnSelectionChange({
    onChange: onSelectionChange
  })

  /**
   * ----- Render -----
   */
  return (
    <div style={{ width: '100%' }}>
      {/* Form */}
      <FormHeaderContainer>
        <FormControlContainer>
          <LanguageMapForm
            supportedLanguages={moduleFormData.supportedLanguages}
            label="Title"
            value={moduleFormData.title}
            onChange={(title) => {
              setModuleFormData({ ...moduleFormData, title })
            }}
          />
          <LanguageMapForm
            supportedLanguages={moduleFormData.supportedLanguages}
            label="Description"
            value={moduleFormData.description || {}}
            onChange={(description) => {
              setModuleFormData({ ...moduleFormData, description })
            }}
          />
        </FormControlContainer>
        <SupportedLanguageForm
          value={moduleFormData.supportedLanguages}
          onChange={(supportedLanguages) => {
            setModuleFormData({ ...moduleFormData, supportedLanguages })
          }}
        />
      </FormHeaderContainer>
      <div
        style={{
          width: '100%',
          height: '75vh',
          border: '1px solid black',
          borderRadius: '10px'
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={updateNodes}
          onEdgesChange={updateEdges}
          isValidConnection={isValidConnection}
          nodeTypes={{
            Message: MessageNode,
            SingleSelectQuestion: SingleSelectQuestionNode,
            MultiSelectQuestion: MultiSelectQuestionNode,
            FreeFormResponse: FreeFormResponseNode
          }}
          defaultViewport={{ x: 0, y: 0, zoom: 1 }}
          proOptions={{ hideAttribution: true }}
          onConnect={addConnection}
        >
          <Background />
          <Controls />
          {selectedNodes.length > 0 && <NodePanel nodeIds={selectedNodes} />}
          <Panel position="top-left">
            <PanelContainer>
              <Add style={{ cursor: 'pointer' }} onClick={handleAddMenuOpen} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAddMenuClose}
              >
                <MenuItem onClick={() => handleAddNode(ModuleTaskType.Message)}>
                  Message
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddNode(ModuleTaskType.SingleSelectQuestion)
                  }
                >
                  Single Select Question
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleAddNode(ModuleTaskType.MultiSelectQuestion)
                  }
                >
                  Multi Select Question
                </MenuItem>
                <MenuItem
                  onClick={() => handleAddNode(ModuleTaskType.FreeFormResponse)}
                >
                  Free Form Response
                </MenuItem>
              </Menu>
            </PanelContainer>
            <PanelContainer style={{ marginTop: '0.5em' }}>
              <Refresh style={{ cursor: 'pointer' }} onClick={refresh} />
            </PanelContainer>
          </Panel>
        </ReactFlow>
      </div>
    </div>
  )
}

export default ModuleForm
