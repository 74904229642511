import {
  Connection,
  Edge,
  OnEdgesChange,
  OnNodesChange,
  useEdgesState,
  useNodesState,
  useReactFlow
} from '@xyflow/react'
import React from 'react'
import dagre from 'dagre'

import { v4 as uuidv4 } from 'uuid'
import { UUID } from 'src/models/misc'
import {
  IFreeFormResponse,
  IMessage,
  IModule,
  IModuleFormData,
  IMultiSelectQuestion,
  ISingleSelectQuestion,
  ModuleTask,
  ModuleTaskNode,
  ModuleTaskType
} from 'src/models/module'
import { createEmptyModuleTask, parseEdgeId } from 'src/utils/module'

/**
 * ----- Types -----
 */

export interface INodesAndEdges {
  nodes: ModuleTaskNode[]
  edges: Edge[]
}

interface IModuleFormProviderProps {
  module?: IModule
  onChange: (moduleFormData: IModuleFormData) => void
}

interface IModuleFormContext {
  module?: IModule
  moduleFormData: IModuleFormData

  nodes: ModuleTaskNode[]
  edges: Edge[]
  onNodesChange: OnNodesChange<ModuleTaskNode>
  onEdgesChange: OnEdgesChange<
    Edge<Record<string, unknown>, string | undefined>
  >
  setNodes: React.Dispatch<React.SetStateAction<ModuleTaskNode[]>>
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>

  setModuleFormData: React.Dispatch<React.SetStateAction<IModuleFormData>>
  generateNodesAndEdges: (
    formData: IModuleFormData
  ) => INodesAndEdges | undefined
  layoutElements: (nodesAndEdges: INodesAndEdges) => INodesAndEdges
  updateTask: (task: ModuleTask) => void
  addTask: (type: ModuleTaskType) => void
  updateEdges: OnEdgesChange
  updateNodes: OnNodesChange<ModuleTaskNode>
  addConnection: (connection: Connection) => void
  updateFirstTask: (taskId: UUID) => void
  refresh: () => void
}

/**
 * ----- Initialize Variables -----
 */

const ModuleFormContext = React.createContext<IModuleFormContext | undefined>(
  undefined
)

/**
 * ----- Provider -----
 */

export const ModuleFormProvider: React.FC<IModuleFormProviderProps> = ({
  children,
  module: moduleProp,
  onChange
}) => {
  /**
   * ----- Hook Initialization
   */

  const [nodes, setNodes, onNodesChange] = useNodesState<ModuleTaskNode>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])

  const [module] = React.useState<IModule | undefined>(moduleProp)
  const [moduleFormData, setModuleFormData] = React.useState<IModuleFormData>({
    title: moduleProp?.title || {},
    description: moduleProp?.description || undefined,
    coverImageUrl: moduleProp?.coverImageUrl || undefined,
    supportedLanguages: moduleProp?.supportedLanguages || ['en'],
    tasks: moduleProp?.tasks || {},
    firstTask: moduleProp?.firstTask || undefined
  })

  const { updateNodeData, getViewport } = useReactFlow()

  /**
   * ----- Functions -----
   */

  const handleEdges = React.useCallback(
    (nodes: ModuleTaskNode[]): Edge[] => {
      const tasks = nodes.map((node) => node.data)
      const visited = new Set<UUID>()
      const queue = [moduleFormData.firstTask]
      const newEdges: Edge[] = []

      while (queue.length > 0) {
        const taskId = queue.shift()
        if (!taskId || visited.has(taskId)) continue
        visited.add(taskId)

        const task = tasks.find((t) => t.taskId === taskId)
        if (!task) continue

        switch (task.type) {
          case ModuleTaskType.SingleSelectQuestion: {
            // For each answer option, create an edge based on navigationMap
            Object.entries(task.navigationMap).forEach(
              ([answerOptionId, nextTaskId]) => {
                newEdges.push({
                  id: `${task.taskId}_${answerOptionId}_${nextTaskId}`,
                  source: task.taskId,
                  sourceHandle: answerOptionId,
                  target: nextTaskId,
                  animated: true
                })
                queue.push(nextTaskId)
              }
            )
            break
          }
          case ModuleTaskType.MultiSelectQuestion:
          case ModuleTaskType.Message:
          case ModuleTaskType.FreeFormResponse: {
            if (task.followUpTask) {
              newEdges.push({
                id: `${task.taskId}_${task.followUpTask}`,
                source: task.taskId,
                target: task.followUpTask,
                animated: true
              })
              queue.push(task.followUpTask)
            }
            break
          }
          default:
            break
        }
      }

      return newEdges
    },
    [moduleFormData.firstTask]
  )

  const generateNodesAndEdges = React.useCallback(
    (formData: IModuleFormData) => {
      if (!formData) return

      const nodes: ModuleTaskNode[] = []

      Object.values(formData.tasks).forEach((task) => {
        // Create node
        nodes.push({
          id: task.taskId,
          type: task.type,
          data: { ...task },
          position: { x: 0, y: 0 },
          width: 500,
          focusable: true
        })
      })

      const edges = handleEdges(nodes)

      return { nodes, edges }
    },
    [handleEdges]
  )

  const layoutElements = React.useCallback(
    ({ nodes, edges }: INodesAndEdges): INodesAndEdges => {
      const dagreGraph = new dagre.graphlib.Graph()
      dagreGraph.setDefaultEdgeLabel(() => ({}))

      dagreGraph.setGraph({ rankdir: 'TB' })

      nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: 250, height: 200 })
      })

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target)
      })

      dagre.layout(dagreGraph)

      nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id)
        node.position = {
          x: nodeWithPosition.x - 250 / 2,
          y: nodeWithPosition.y - 200 / 2
        }

        node.data = {
          ...node.data
        }
      })

      return { nodes, edges }
    },
    []
  )

  const updateTask = React.useCallback(
    (task: ModuleTask) => {
      setModuleFormData((prev) => {
        return {
          ...prev,
          tasks: {
            ...prev.tasks,
            [task.taskId]: task
          }
        }
      })

      updateNodeData(task.taskId, { ...task })

      const newNodes = nodes.map((node) => {
        if (node.id === task.taskId) {
          return {
            ...node,
            data: { ...task }
          }
        }
        return node
      })
      setEdges(handleEdges(newNodes))
    },
    [handleEdges, nodes, setEdges, updateNodeData]
  )

  const updateEdges: OnEdgesChange = React.useCallback(
    (changes) => {
      changes.forEach((change) => {
        switch (change.type) {
          case 'remove': {
            const parsedId = parseEdgeId(change.id)
            if (parsedId.selectableOptionId) {
              // Single select question
              const task: ISingleSelectQuestion = moduleFormData.tasks[
                parsedId.taskId
              ] as ISingleSelectQuestion
              if (!task) return

              task.navigationMap = Object.fromEntries(
                Object.entries(task.navigationMap).filter(
                  ([key]) => key !== parsedId.selectableOptionId
                )
              )

              updateTask(task)
            } else {
              // Other types
              const task = moduleFormData.tasks[parsedId.taskId] as
                | IMultiSelectQuestion
                | IMessage
                | IFreeFormResponse
              if (!task) return

              task.followUpTask = undefined

              updateTask(task)
            }

            break
          }
          default:
            onEdgesChange(changes)
        }
      })
    },
    [moduleFormData.tasks, onEdgesChange, updateTask]
  )

  const removeTask = React.useCallback(
    (taskId: string) => {
      const moduleTasks = moduleFormData.tasks
      if (!moduleTasks[taskId]) return
      delete moduleTasks[taskId]
      setModuleFormData((prev) => {
        return {
          ...prev,
          tasks: moduleTasks
        }
      })
    },
    [moduleFormData.tasks]
  )

  const updateNodes: OnNodesChange<ModuleTaskNode> = React.useCallback(
    (changes) => {
      changes.forEach((change) => {
        if (change.type === 'remove') {
          removeTask(change.id)
        }
      })
      onNodesChange(changes)
    },
    [onNodesChange, removeTask]
  )

  const refresh = React.useCallback(() => {
    const nodesAndEdges = generateNodesAndEdges(moduleFormData)
    if (!nodesAndEdges) return

    const laidOutNodesAndEdges = layoutElements(nodesAndEdges)

    setNodes(laidOutNodesAndEdges.nodes)
    setEdges(laidOutNodesAndEdges.edges)
  }, [
    moduleFormData,
    generateNodesAndEdges,
    layoutElements,
    setEdges,
    setNodes
  ])

  const updateFirstTask = React.useCallback(
    (taskId: UUID) => {
      setModuleFormData((prev) => {
        return {
          ...prev,
          firstTask: taskId
        }
      })
      refresh()
    },
    [refresh]
  )

  const addTask = React.useCallback(
    (type: ModuleTaskType) => {
      const taskId = uuidv4()
      const viewport = getViewport()

      const currentTaskLength = Object.keys(moduleFormData.tasks).length

      const task = createEmptyModuleTask(
        type,
        taskId,
        moduleFormData.supportedLanguages
      )

      setModuleFormData((prev) => {
        return {
          ...prev,
          tasks: {
            ...prev.tasks,
            [taskId]: task
          }
        }
      })
      setNodes((nodes) => [
        ...nodes,
        {
          id: taskId,
          type,
          data: {
            ...task
          },
          position: { x: -viewport.x, y: -viewport.y },
          width: 500,
          focusable: true
        }
      ])

      if (currentTaskLength === 0) {
        setModuleFormData((prev) => {
          return {
            ...prev,
            firstTask: taskId
          }
        })
      }
    },
    [
      getViewport,
      moduleFormData.supportedLanguages,
      moduleFormData.tasks,
      setNodes
    ]
  )

  const addConnection = React.useCallback(
    (connection: Connection) => {
      const task = moduleFormData.tasks[connection.source]
      const followUpTask = moduleFormData.tasks[connection.target]

      if (!task || !followUpTask) return

      if (connection.sourceHandle) {
        // Single select question
        const singleSelectQuestion = task as ISingleSelectQuestion
        singleSelectQuestion.navigationMap = {
          ...singleSelectQuestion.navigationMap,
          [connection.sourceHandle]: connection.target
        }

        updateTask(singleSelectQuestion)
      } else {
        // Other types
        const otherTask = task as
          | IMultiSelectQuestion
          | IMessage
          | IFreeFormResponse
        otherTask.followUpTask = connection.target
        updateTask(task)
      }
    },
    [moduleFormData.tasks, updateTask]
  )

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    onChange(moduleFormData)
  }, [moduleFormData, onChange])

  /**
   * ----- Render -----
   */

  return (
    <ModuleFormContext.Provider
      value={{
        module,
        moduleFormData,
        setModuleFormData,
        generateNodesAndEdges,
        layoutElements,
        updateTask,
        updateEdges,
        updateNodes,
        addConnection,
        addTask,
        updateFirstTask,
        refresh,

        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        setNodes,
        setEdges
      }}
    >
      {children}
    </ModuleFormContext.Provider>
  )
}

/**
 * ----- Hooks -----
 */

export const useModuleFormContext = () => {
  const context = React.useContext(ModuleFormContext)
  if (!context) {
    throw new Error(
      'useModuleFormContext must be used within a ModuleFormProvider'
    )
  }
  return context
}
